import React from 'react'

function WAAC({ finalWaac }) {
  return (
    <div>
      <h2>WAAC: {finalWaac} %</h2>
      <h5>WAAC é o custo de capital, necessário para calcular o valuation</h5>
    </div>
  )
}

export default WAAC
